.react-clock {
  display: block;
  position: relative;
}
.react-clock,
.react-clock *,
.react-clock *:before,
.react-clock *:after {
  box-sizing: border-box;
}
.react-clock__face {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid black;
  border-radius: 50%;
}
.react-clock__hand {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}
.react-clock__hand__body {
  position: absolute;
  background-color: black;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.react-clock__mark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}
.react-clock__mark__body {
  position: absolute;
  background-color: black;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.react-clock__mark__number {
  position: absolute;
  left: -40px;
  width: 80px;
  text-align: center;
}
.react-clock__second-hand__body {
  background-color: red;
}

.react-time-picker {
  display: inline-flex;
  position: relative;
}
.react-time-picker,
.react-time-picker *,
.react-time-picker *:before,
.react-time-picker *:after {
  box-sizing: border-box;
}
.react-time-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
}
.react-time-picker__inputGroup {
  min-width: calc((4px * 3) +  0.54em * 6  +  0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}
.react-time-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-time-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-time-picker__inputGroup__input::-webkit-outer-spin-button,
.react-time-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-time-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-time-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px +  0.54em);
}
.react-time-picker__inputGroup__amPm {
  font: inherit;
  -moz-appearance: menulist;
}
.react-time-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-time-picker__button:enabled {
  cursor: pointer;
}
.react-time-picker__button:enabled:hover .react-time-picker__button__icon,
.react-time-picker__button:enabled:focus .react-time-picker__button__icon {
  stroke: #0078d7;
}
.react-time-picker__button:disabled .react-time-picker__button__icon {
  stroke: #6d6d6d;
}
.react-time-picker__button svg {
  display: inherit;
}
.react-time-picker__clock {
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  background-color: white;
  border: thin solid #a0a096;
  z-index: 1;
}
.react-time-picker__clock--closed {
  display: none;
}

